// React / Redux
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components JSX
import SvgIcon from '../../../common/svg-icon';
import { setQuoteDetails } from '../../../data/slices/quote-details-slice';
import useTranslations from '../../../hooks/use-translations';

export default function OptionCard({ option, setInfoWindow }) {
    // Translations
    const globalTranslations = useTranslations('GLOBAL');

    const quoteDetails = useSelector((store) => store.quoteDetails);
    const quoteDetailsDispatch = useDispatch();
    const selectedOption = quoteDetails?.MARQUEE_OPTIONS?.find(
        (optionToCheck) => optionToCheck.option_id === option.option_id
    );

    const isQuantity = option.option_quantity === 'true';
    const [quantitySelected, setQuantitySelected] = useState(
        selectedOption?.qty || 0
    );

    const linenClothRef = useRef();

    const handleOptionSelection = useCallback(
        (e) => {
            e.stopPropagation();

            if (isQuantity && quantitySelected === 0) {
                return;
            }

            if (selectedOption) {
                quoteDetailsDispatch(
                    setQuoteDetails({
                        MARQUEE_OPTIONS: quoteDetails?.MARQUEE_OPTIONS.filter(
                            (optionToCheck) =>
                                optionToCheck.option_id !== option.option_id
                        ),
                        OPTION_COUNT: quoteDetails?.OPTION_COUNT - 1
                    })
                );
            } else {
                quoteDetailsDispatch(
                    setQuoteDetails({
                        MARQUEE_OPTIONS: [
                            ...(quoteDetails?.MARQUEE_OPTIONS || []),
                            {
                                ...option,
                                ...(isQuantity
                                    ? {
                                          qty: quantitySelected,
                                          addLinenCloth:
                                              linenClothRef?.current?.checked
                                      }
                                    : {})
                            }
                        ],
                        OPTION_COUNT: quoteDetails?.OPTION_COUNT + 1
                    })
                );
            }
        },
        [
            isQuantity,
            quantitySelected,
            selectedOption,
            quoteDetailsDispatch,
            quoteDetails?.MARQUEE_OPTIONS,
            quoteDetails?.OPTION_COUNT,
            option
        ]
    );

    const handleMoreInfo = (e) => {
        e.stopPropagation();

        setInfoWindow(option.option_description);
    };

    useEffect(() => {
        if (selectedOption?.qty) {
            setQuantitySelected(selectedOption?.qty);
        }
    }, [selectedOption]);

    return (
        <div
            className={`option-card ${
                selectedOption ? 'option-card--selected' : ''
            }`}
            onClick={handleOptionSelection}
            role="button"
        >
            <h3 className="option-card__name">
                {option.option_name}
                {option.option_description && (
                    <button
                        className="option-card__more-info"
                        onClick={handleMoreInfo}
                    >
                        i
                    </button>
                )}
            </h3>

            {isQuantity && (
                <>
                    <QuantityField
                        initialValue={quantitySelected}
                        onUpdate={(qty) => {
                            if (selectedOption) {
                                if (qty === 0) {
                                    quoteDetailsDispatch(
                                        setQuoteDetails({
                                            MARQUEE_OPTIONS:
                                                quoteDetails?.MARQUEE_OPTIONS.filter(
                                                    (optionToCheck) =>
                                                        optionToCheck.option_id !==
                                                        option.option_id
                                                ),
                                            OPTION_COUNT:
                                                quoteDetails?.OPTION_COUNT - 1
                                        })
                                    );
                                } else {
                                    quoteDetailsDispatch(
                                        setQuoteDetails({
                                            MARQUEE_OPTIONS:
                                                quoteDetails?.MARQUEE_OPTIONS?.map(
                                                    (opt) => {
                                                        if (
                                                            opt.option_id ===
                                                            option.option_id
                                                        ) {
                                                            return {
                                                                ...opt,
                                                                qty: qty
                                                            };
                                                        }

                                                        return opt;
                                                    }
                                                )
                                        })
                                    );
                                }
                            }

                            setQuantitySelected(qty);
                        }}
                        maxQuantity={parseInt(option.option_max_quantity)}
                    />

                    {quantitySelected > 0 &&
                        option.option_linen_cloth === 'true' && (
                            <label className="option-card__checkbox">
                                <input ref={linenClothRef} type="checkbox" />

                                <div>
                                    <span>{globalTranslations?.ADD_LINEN}</span>
                                    <span>
                                        £{option.option_linen_cloth_price}{' '}
                                        {globalTranslations?.EACH}
                                    </span>
                                </div>
                            </label>
                        )}

                    {option.option_max_quantity && (
                        <span className="option-card__max-qty">
                            Max Quantity: {option.option_max_quantity}
                        </span>
                    )}
                </>
            )}

            <div className="option-card__select-button">
                <p className="option-card__select-button-price">
                    £{option.option_price}
                </p>

                {selectedOption ? (
                    <>
                        {isQuantity ? (
                            <p className="option-card__select-button-text">
                                {selectedOption.qty}{' '}
                                {globalTranslations?.SELECTED}
                            </p>
                        ) : (
                            <SvgIcon
                                name="tick"
                                cssClass="option-card__select-button-icon"
                            />
                        )}
                    </>
                ) : (
                    <p
                        className={`option-card__select-button-text ${
                            isQuantity && quantitySelected === 0
                                ? 'option-card__select-button-text--disabled'
                                : ''
                        }`}
                    >
                        {globalTranslations?.SELECT}
                    </p>
                )}
            </div>
        </div>
    );
}

const QuantityField = ({ initialValue, onUpdate, maxQuantity }) => {
    const [quantity, setQuantity] = useState(initialValue);

    const handleIncrement = useCallback(
        (evt) => {
            evt.stopPropagation();

            setQuantity((prevQuantity) =>
                prevQuantity >= maxQuantity ? prevQuantity : prevQuantity + 1
            );
            onUpdate(quantity >= maxQuantity ? quantity : quantity + 1);
        },
        [maxQuantity, onUpdate, quantity]
    );

    const handleDecrement = useCallback(
        (evt) => {
            evt.stopPropagation();

            if (quantity >= 1) {
                setQuantity((prevQuantity) => prevQuantity - 1);
                onUpdate(quantity - 1);
            }
        },
        [onUpdate, quantity]
    );

    const handleChange = (evt) => {
        evt.stopPropagation();

        const newQuantity = parseInt(evt.target.value);

        if (!isNaN(newQuantity) && newQuantity <= parseInt(maxQuantity)) {
            setQuantity(newQuantity);
            onUpdate(newQuantity);
        } else {
            setQuantity(0);
            onUpdate(0);
        }
    };

    const handleInputClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="option-card__quantity-field">
            <button onClick={handleDecrement}>
                <SvgIcon name="minus" />
            </button>
            <input
                type="number"
                value={initialValue || quantity}
                onChange={handleChange}
                onClick={handleInputClick}
                disabled={'disabled'}
            />
            <button
                onClick={handleIncrement}
                disabled={quantity === maxQuantity && 'disabled'}
            >
                <SvgIcon name="add" />
            </button>
        </div>
    );
};
